import './bootstrap';

import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

import AlpineRayPlugin from 'alpinejs-ray';
import Autosize from '@marcreichel/alpine-autosize';
import resize from '@alpinejs/resize'

import sort from '@alpinejs/sort';


import flatpickr from 'flatpickr';
import { Polish } from "flatpickr/dist/l10n/pl.js"

window.Polish = Polish;

import '../../vendor/masmerise/livewire-toaster/resources/js';



// import { flare } from "@flareapp/js";

import * as Sentry from "@sentry/browser";


import 'add-to-calendar-button';

import Chart from 'chart.js/auto';

import Splide from '@splidejs/splide';

import tippy from 'tippy.js';

import {
  createLocalTracks,
  createLocalVideoTrack,
  createLocalAudioTrack,
  Room,
  RoomEvent,
  RemoteParticipant,
  RemoteTrackPublication,
  RemoteTrack,
  Participant,
  VideoPresets,
  VideoPresets43,
  ParticipantEvent,
  MediaDeviceFailure,
  Track,
  VideoQuality,
  LocalAudioTrack,
  LocalVideoTrack
} from 'livekit-client';

window.Chart = Chart;

window.Splide = Splide;

window.tippy = tippy;

window.createLocalTracks = createLocalTracks;
window.createLocalVideoTrack = createLocalVideoTrack;
window.createLocalAudioTrack = createLocalAudioTrack;
window.Room = Room;
window.RoomEvent = RoomEvent;
window.RemoteParticipant = RemoteParticipant;
window.RemoteTrackPublication = RemoteTrackPublication;
window.RemoteTrack = RemoteTrack;
window.Participant = Participant;
window.VideoPresets = VideoPresets;
window.VideoPresets43 = VideoPresets43;
window.ParticipantEvent = ParticipantEvent;
window.MediaDeviceFailure = MediaDeviceFailure;
window.Track = Track;
window.VideoQuality = VideoQuality;
window.LocalAudioTrack = LocalAudioTrack;
window.LocalVideoTrack = LocalVideoTrack;

window.translate = function(string, args){
  let value = _.get(window.i18n, string);

  _.eachRight(args, (paramVal, paramKey) => {
    value = _.replace(value, `:${paramKey}`, paramVal);
  });

  return value;
}

const defaultDevice = {
  audioinput: { 'label': translate('streaming.Default Audio Input'), 'deviceId': '0' },
  videoinput: { 'label': translate('streaming.Default Video Input'), 'deviceId': '0' },
  audiooutput: { 'label': translate('streaming.Default Audio Output'), 'deviceId': '0' },
};

window.defaultDevice = defaultDevice;

window.waitTime = import.meta.env.VITE_BELL_WAIT;




// only launch in production, we don't want to waste your quota while you're developing.
// if (process.env.NODE_ENV != 'local') {
//     flare.light();
// }


if (process.env.NODE_ENV != 'local') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: import.meta.env.VITE_SENTRY_PROJECT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  // test sentry
  // setTimeout(() => {
  //   throw new Error("Sentry Test Error");
  // });
}



window.Alpine = Alpine;


Alpine.plugin(AlpineRayPlugin);
Alpine.plugin(Autosize);
Alpine.plugin(sort);
Alpine.plugin(resize);

Alpine.magic('clipboard', () => {
    return subject => {
      return navigator.clipboard.writeText(subject);
    };
});

Livewire.start();
